import router from '@/router'

const routes = [
  {
    path: '/FAQ',
    name: 'FAQ',
    meta: {
      title: 'FAQ'
    },
    components: {
      default: () => import(/* webpackChunkName: "FAQ" */ './views/FAQ.vue')
    }
  }
]

router.addRoutes(routes)

export default routes
