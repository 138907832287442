
















import Vue from 'vue'

export default Vue.extend({
  props: {
    loadingText: {
      type: String,
      default: 'Loading...'
    }
  }
})
