import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { PluginObject } from 'vue/types/umd'

export interface IApplicationInsightsOptions {
  id: string
}

const plugin: PluginObject<IApplicationInsightsOptions> = {
  install(_Vue, options) {
    if (process.env.NODE_ENV !== 'production') {
      return
    }
    const { id } = options

    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: id,
        enableAutoRouteTracking: true
      }
    })

    appInsights.loadAppInsights()
  }
}

export default plugin
