import { AxiosResponse } from 'axios'
import { ODataRequest } from '@/store/ODataStore'

export default class ODataResult<T> {
  count: number
  value: T[]
  request: ODataRequest
  constructor(response?: AxiosResponse, request?: ODataRequest) {
    this.count = response?.data['@odata.count'] || 0
    this.value = response?.data?.value || []
    this.request = request
  }
}
