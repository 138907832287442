import { UserAgentApplication } from 'msal'
import { PluginObject } from 'vue/types/umd'

declare module 'vue/types/vue' {
  interface Vue {
    $auth: UserAgentApplication
  }
}

export interface IMsalOptions {
  clientId: string,
  tenantId: string
}

const plugin: PluginObject<IMsalOptions> = {
  install(_Vue, options) {
    const msal = new UserAgentApplication({
      auth: {
        clientId: options.clientId,
        authority: `https://login.microsoftonline.com/${options.tenantId}`
      },
      cache: {
        cacheLocation: 'localStorage'
      }
    })
    _Vue.prototype.$auth = msal
  }
}

export default plugin
