















import FormMixin from '@/mixins/FormMixin'
import StarSelector from './StarSelector.vue'

export default FormMixin.extend({
  components: {
    StarSelector
  },
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
  }
})
