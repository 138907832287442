<template>
  <v-dialog v-model="dialog" :max-width="maxWidth" persistent scrollable>
    <template #activator="{ on }">
      <div v-on="on">
        <slot />
      </div>
    </template>
    <v-card>
      <v-card-title class="pb-1">
        <span class="headline">{{ question }}</span>
      </v-card-title>

      <v-card-text class="pt-1">{{ description }}</v-card-text>

      <v-card-actions>
        <v-btn
          v-if="showCancelButton"
          color="secondary"
          @click="$emit('close')"
          >{{ cancelButtonText }}</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="$emit('confirm')">{{
          confirmButtonText
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    showDialog: Boolean,
    showCancelButton: {
      type: Boolean,
      default: true
    },
    question: String,
    description: String,
    cancelButtonText: {
      type: String,
      default: 'Cancel'
    },
    confirmButtonText: {
      type: String,
      default: 'Ok'
    },
    maxWidth: {
      type: String,
      default: '400px'
    }
  },
  data() {
    return {
      dialog: false
    }
  },
  watch: {
    showDialog(val) {
      this.dialog = val
    }
  }
}
</script>
