import router from '@/router'

router.addRoutes([
  {
    path: '/ReportBuilder/:id?',
    name: 'ReportBuilder',
    meta: {
      title: 'Report Builder'
    },
    components: {
      default: () => import(/* webpackChunkName: "QueryEditor" */ './views/QueryEditor.vue')
    }
  },
  {
    path: '/Reports',
    name: 'Reports',
    meta: {
      title: 'Reports'
    },
    components: {
      default: () => import(/* webpackChunkName: "Queries" */ './views/Queries.vue')
    }
  }
])
