<style scoped>
a {
  text-decoration: none;
  color: inherit;
}
</style>

<style>
div.v-toolbar__content {
  max-width: 1399px;
  margin: auto;
}
</style>

<template>
  <div>
    <v-app-bar app flat>
      <v-toolbar-title>
        <router-link :to="{ name: 'Home' }" class="grey--text">
          <v-row align="center" class="mr-4" style="width: 270px" no-gutters>
            <v-img
              src="/logo.png"
              width="72"
              class="d-inline mr-2"
            />EOS Insights
          </v-row>
        </router-link>
      </v-toolbar-title>
      <v-toolbar-items>
        <!-- <v-divider vertical />
        <v-btn text :to="{ name: 'Home' }"
          >Home</v-btn
        > -->
        <v-divider vertical />
        <v-btn text :to="{ name: 'CustomerLookup' }"
          >Customer Lookup</v-btn
        >
        <v-divider vertical />
        <v-btn text :to="{ name: 'MyCustomers' }"
          >Customer Details</v-btn
        >
        <v-divider vertical />
        <v-btn text :to="{ name: 'ReportBuilder' }"
          >Report Builder</v-btn
        >
        <v-divider vertical />
        <v-btn text :to="{ name: 'Dashboard' }"
          >Regions (PBI)</v-btn
        >
        <v-divider vertical />
        <v-btn text :to="{ name: 'Reports' }"
          >Saved Reports</v-btn
        >
        <v-divider vertical />
        <v-btn text :to="{ name: 'FAQ' }"
          >FAQ</v-btn
        >
        <v-divider vertical />
      </v-toolbar-items>
      <v-spacer />
      <!-- <v-btn icon small download href="./user_guide.docx">
        <v-icon small>help</v-icon>
      </v-btn> -->
      <v-toolbar-title>
        <span>Welcome {{ userName }}</span
            >
        <!-- <v-menu nudge-bottom="45">
          <template #activator="{ on }">
            <span style="cursor: pointer" v-on="on"
              >Welcome {{ userName }}</span
            >
          </template>
          <v-list>
            <v-list-item @click="logout">
              <v-list-item-icon>
                <v-icon>$logout</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu> -->
      </v-toolbar-title>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  components: {
  },
  data: () => ({
  }),
  computed: {
    userName() {
      const account = this.$auth.getAccount()

      if (account) {
        let name = account.name

        if (account.idTokenClaims) {
          const givenNameClaim = account.idTokenClaims.given_name
          if (givenNameClaim) {
            name = givenNameClaim
          }
        }

        return name
      }

      return ''
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('Auth/logout')
    }
  }
}
</script>
