import store from '@/store'
import ODataStore, { ODataState, ODataRequest } from '@/store/ODataStore'
import IUserFeedback from './models/IUserFeedback'

export interface IUserFeedbackStoreState extends Partial<ODataState<IUserFeedback>> {
  userFeedback: IUserFeedback
  visits: number,
  visitsFeedbackShown: boolean
}

store.registerModule('UserFeedback', ODataStore.extend<IUserFeedbackStoreState>({
  namespaced: true,
  state: {
    userFeedback: null,
    visits: Number(localStorage.getItem('visits')),
    visitsFeedbackShown: Boolean(localStorage.getItem('visits-feedback-shown'))
  },
  getters: {
    userFeedback(state) {
      return state.userFeedback
    },
    visits(state) {
      return state.visits
    },
    visitsFeedbackShown(state) {
      return state.visitsFeedbackShown
    }
  },
  mutations: {
    setUserFeedback(state, userFeedback: IUserFeedback) {
      state.userFeedback = userFeedback
    },
    setVisits(state, visits: number) {
      state.visits = visits
      localStorage.setItem('visits', `${visits}`)
    },
    setVisitsFeedbackShown(state, visitsFeedbackShown: boolean) {
      state.visitsFeedbackShown = visitsFeedbackShown
      localStorage.setItem('visits-feedback-shown', `${visitsFeedbackShown}`)
    }
  },
  actions: {
    async showDialog({ commit, dispatch }) {
      commit('setUserFeedback', {
        stars: 0,
        comment: ''
      })

      try {
        const request: ODataRequest = {
          url: 'Metrics/IncrementGraphFeedbackViews'
        }
        await dispatch('post', request)
      } catch {}
    },
    closeDialog({ commit }) {
      commit('setUserFeedback', null)
    },
    incrementVisits({ commit, dispatch, state }) {
      let visits = state.visits
      const feedbackShown = state.visitsFeedbackShown

      commit('setVisits', ++visits)

      if (!feedbackShown && visits === 3) {
        dispatch('showDialog')
        commit('setVisits', 0)
        commit('setVisitsFeedbackShown', true)
      } else if (feedbackShown && visits % 10 === 0) {
        dispatch('showDialog')
        commit('setVisits', 0)
      }
    },
    async save({ commit, dispatch }, userFeedback: IUserFeedback) {
      const request: ODataRequest = {
        url: 'UserFeedback',
        data: userFeedback
      }
      const result = await dispatch('post', request)
      commit('setUserFeedback', null)
      return result.id
    }
  }
}))
