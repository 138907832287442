import store from '@/store'
import ODataStore, { ODataState, ODataRequest } from '@/store/ODataStore'
import IDateFormat from './models/IDateFormat'

export interface IDateFormatStoreState extends Partial<ODataState<IDateFormat>> {
  default: IDateFormat
}

store.registerModule('DateFormats', ODataStore.extend<IDateFormatStoreState>({
  namespaced: true,
  state: {
    default: null
  },
  getters: {
    results(state) {
      return state.result.value
    },
    default: state => {
      return state.default
    }
  },
  actions: {
    async getByLocale({ dispatch }, locale) {
      const request: ODataRequest = {
        url: `DateFormats('${locale}')`
      }
      const dateFormat = await dispatch('get', request)
      return dateFormat
    },
    async load({ commit, dispatch }) {
      const request: ODataRequest = {
        url: 'DateFormats'
      }
      const result = await dispatch('get', request)
      commit('setResult', result)
    },
    async setDefault({ commit, dispatch }, locale) {
      const request: ODataRequest = {
        url: `DateFormats('${locale}')`
      }
      const result = await dispatch('get', request)
      commit('setDefault', result)
    }
  },
  mutations: {
    setDefault(state, result) {
      state.default = result
    }
  }
}))
