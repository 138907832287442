import Vue from 'vue'
import vuetify from '@/plugins/vuetify'
import '@/plugins/filters'
import '@/plugins/veeValidate'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store/index'
import AppInsights from '@/plugins/application-insights'
import Msal from '@/plugins/msal'
import Page from '@/components/Page.vue'
import Form from '@/components/Form.vue'
import Dialog from '@/components/Dialog.vue'
import Overlay from '@/components/Overlay.vue'
import DeleteConfirmDialog from '@/components/DeleteConfirmDialog.vue'
import DatePicker from '@/components/DatePicker.vue'
import TimePicker from '@/components/TimePicker.vue'
import NoData from '@/components/NoData.vue'
import Loading from '@/components/Loading.vue'
import TextField from '@/components/TextField.vue'
import SelectField from '@/components/SelectField.vue'
import TextAreaField from '@/components/TextAreaField.vue'
import ComboBox from '@/components/ComboBox.vue'
import Checkbox from '@/components/Checkbox.vue'
import Panel from '@/components/Panel.vue'
import '@/areas'
import { get, cloneDeep } from 'lodash-es'
import Formatter from './utils/Formatter'
import Axios from 'axios'

Vue.config.productionTip = false

// add global helpers
Vue.prototype.$get = get
Vue.prototype.$clone = cloneDeep
Vue.prototype.$formatter = Formatter

declare module 'vue/types/vue' {
  interface Vue {
    $get: typeof get,
    $clone: typeof cloneDeep,
    $formatter: typeof Formatter
  }
}

// Register global components
Vue.component('Page', Page)
Vue.component('Form', Form)
Vue.component('Dialog', Dialog)
Vue.component('Overlay', Overlay)
Vue.component('DeleteConfirmDialog', DeleteConfirmDialog)
Vue.component('DatePicker', DatePicker)
Vue.component('TimePicker', TimePicker)
Vue.component('NoData', NoData)
Vue.component('Loading', Loading)
Vue.component('TextField', TextField)
Vue.component('SelectField', SelectField)
Vue.component('TextAreaField', TextAreaField)
Vue.component('ComboBox', ComboBox)
Vue.component('Checkbox', Checkbox)
Vue.component('Panel', Panel)

const vm = new Vue({
  vuetify,
  router,
  store,
  data: {
    initialized: false
  },
  render: h => h(App),
  async created() {
    // load configuration
    await store.dispatch('Config/getConfig')
    const clientId = store.getters['Config/clientId']
    const tenantId = store.getters['Config/tenantId']
    const instrumentationKey = store.getters['Config/instrumentationKey']

    // configure axios
    Axios.defaults.baseURL = store.getters['Config/baseUrl']

    // configure axios interceptors
    Axios.interceptors.request.use(async config => {
      const token = await store.dispatch('Auth/login', null, { root: true })
      config.headers = config.headers ?? {}
      config.headers.Authorization = `Bearer ${token}`
      return config
    })

    // initialize application insights module
    Vue.use(AppInsights, { id: instrumentationKey })
    // initialize msal (auth) module
    Vue.use(Msal, { clientId, tenantId })

    this.initialized = true
  }
})

// wait for the vue instance to finish being initialized before rendering
vm.$watch('initialized', (newValue) => {
  if (newValue) {
    vm.$mount('#app')
  }
})
