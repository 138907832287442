<template>
  <v-dialog
    eager
    @input="change"
    value="true"
    :max-width="width"
    :persistent="persistent"
    @keydown.esc="choose(false)"
  >
    <v-card tile>
      <v-card-title v-if="Boolean(title)" :class="color" primary-title>
        <v-icon v-if="Boolean(icon)" left>{{ icon }}</v-icon>
        {{ title }}
      </v-card-title>
      <!-- <v-toolbar v-if="Boolean(title)" dark :color="color" dense flat>
        <v-icon v-if="Boolean(icon)" left>{{ icon }}</v-icon>
        <v-toolbar-title v-text="title" />
      </v-toolbar>-->
      <v-card-text class="body-1 py-3" v-html="message" />
      <v-card-actions>
        <v-btn
          v-if="Boolean(buttonFalseText)"
          :color="buttonFalseColor"
          @click="choose(false)"
          >{{ buttonFalseText }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-if="Boolean(buttonTrueText)"
          :color="buttonTrueColor"
          @click="choose(true)"
          >{{ buttonTrueText }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  VCard,
  VCardActions,
  VCardText,
  VDialog,
  VIcon,
  VSpacer,
  VBtn
} from 'vuetify/lib'
export default {
  components: {
    VCard,
    VCardActions,
    VCardText,
    VDialog,
    VIcon,
    VSpacer,
    VBtn
  },
  props: {
    buttonTrueText: {
      type: String,
      default: 'Yes'
    },
    buttonFalseText: {
      type: String,
      default: 'No'
    },
    buttonTrueColor: {
      type: String,
      default: 'primary'
    },
    buttonFalseColor: {
      type: String,
      default: 'secondary'
    },
    color: {
      type: String,
      default: 'grey lighten-4'
    },
    icon: {
      type: String
    },
    message: {
      type: String,
      required: true
    },
    persistent: Boolean,
    title: {
      type: String,
      default: 'Confirmation'
    },
    width: {
      type: Number,
      default: 450
    }
  },
  data() {
    return {
      value: false
    }
  },
  mounted() {
    document.addEventListener('keyup', this.onEnterPressed)
  },
  destroyed() {
    document.removeEventListener('keyup', this.onEnterPressed)
  },
  methods: {
    onEnterPressed(e) {
      if (e.keyCode === 13) {
        e.stopPropagation()
        this.choose(true)
      }
    },
    choose(value) {
      this.$emit('result', value)
      this.value = value
      this.$destroy()
    },
    change() {
      this.$destroy()
    }
  }
}
</script>
