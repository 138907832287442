import router from '@/router'

router.addRoutes([
  {
    path: '/',
    name: 'Home',
    alias: '/Home',
    meta: {
      title: 'Home'
    },
    components: {
      default: () => import(/* webpackChunkName: "Home" */ './views/Home.vue')
    }
  }
])
