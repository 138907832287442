import store from '@/store'
import ODataStore, { ODataState, ODataRequest } from '@/store/ODataStore'
import IProcess from './models/IProcess'

export interface IProcessStoreState extends Partial<ODataState<IProcess>> {
  mssalesProcess: IProcess
  ssetProcess: IProcess
}

store.registerModule('Processes', ODataStore.extend<IProcessStoreState>({
  namespaced: true,
  state: {
    mssalesProcess: null,
    ssetProcess: null
  },
  getters: {
    mssalesProcess(state) {
      return state.mssalesProcess
    },
    ssetProcess(state) {
      return state.ssetProcess
    }
  },
  mutations: {
    setmssalesProcess(state, value: IProcess) {
      state.mssalesProcess = value
    },
    setssetProcess(state, value: IProcess) {
      state.ssetProcess = value
    }
  },
  actions: {
    async loadMSSalesProcess({ dispatch, commit }) {
      const request: ODataRequest = {
        url: 'Processes/Insights?$filter=processId eq 5 and status eq \'Complete\'&$orderby=startedOn desc&$top=1'
      }
      const response = await dispatch('get', request)
      commit('setmssalesProcess', response.value[0])
    },
    async loadSSETProcess({ dispatch, commit }) {
      const request: ODataRequest = {
        url: 'Processes/Insights?$filter=processId eq 3 and status eq \'Complete\'&$orderby=startedOn desc&$top=1'
      }
      const response = await dispatch('get', request)
      commit('setssetProcess', response.value[0])
    }
  }
}))
