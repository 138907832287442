





































import Vue from 'vue'

export default Vue.extend({
  props: {
    title: String,
    titleIcon: String,
    loading: Boolean,
    skeletonLoader: String,
    error: Object,
    noData: Boolean,
    noDataText: String,
    tooltip: String
  },
  computed: {
    color(): string {
      return 'grey lighten-3'
    }
  }
})
